<template>
	<div style="display: flex; height: 100%; padding: 0; max-width: 100%; flex-direction: column;">
		<div style="display: flex; flex: 1; padding: 0; max-width: 100%;" :style="{maxHeight: showLogs ? 'calc(100% - 121px)' : '100%'}">
			<div class="navigationContainer" :style="{width: navigationWidth + 'px', minWidth: navigationWidth + 'px'}">
				<InterfaceIntegratedEditorSearch />
				<div class="navigation bl-light-scroll">
					<InterfaceIntegratedEditorNavigation :tree="tree.nodes" v-if="tree" />
				</div>
			</div>
			<div v-bl-resize="{value: navigationWidth, min: 100}" @resize="navigationWidth = $event; saveState()" @currentresize="previewDocResizing = $event"></div>
			<div class="contentContainer">
				<div class="filePath">{{ activeTab?.displayPath }}</div>
				<ul class="tabs bl-light-scroll" @wheel="wheelTabHandler($event)" ref="tabContainer">
					<li v-for="tab in tabs" :key="tab.tabId" @click="openTab(tab)" :class="{active: tab.active}" @auxclick="closeTab(tab, $event);">
						<icon :style="{color: tab.tabColor}">{{ tab.tabIcon }}</icon>
						<span v-if="tab.tabLabel">{{ tab.tabLabel }}{{ tab.tabExtension ? '.' + tab.tabExtension : '' }}</span>
						<span v-else><em>New element</em></span>
						<button class="bl-icon-button" @click="closeTab(tab); $event.stopPropagation();">close</button>
					</li>
				</ul>
				<div style="flex: 1; max-height: calc(100% - 36px); max-width: 100%; overflow-x: auto; display: flex;">
					<div v-if="activeTab?.errors" class="tabErrors">
						<icon @click="activeTab.errors = null">close</icon>
						<pre>{{ activeTab.errors }}</pre>
					</div>
					<div ref="codeEditor" style="min-height: 100%; flex: 1;" :style="{display: activeTab?.codeModeEnabled ? null : 'none'}"></div>
					<div v-for="tab in tabs" :key="tab.tabId" :style="{display: tab.active && !tab.codeModeEnabled ? 'flex' : 'none'}" style="min-height: 100%; flex-direction: column; flex: 1;">
						<div style="display: flex; flex: 1; overflow: auto;">
							<div style="flex: 1;">
								<component :is="tab.object.component" :id="tab.object.id" :namespace="tab.object.nsId" :ref="el => tab.component = el" />
							</div>
						</div>
					</div>
					<div style="flex: 1; background-color: var(--bl-surface)" v-if="!activeTab"></div>
					<div class="sideBar">
						<div v-if="activeTab">
							<button v-if="activeTab.object.formMode && activeTab.object.codeMode" class="bl-icon-button" v-bl-tooltip="'Form view'" :class="{active: !activeTab.codeModeEnabled}" @click="setCodeMode(false)">forms_apps_script</button>
							<button v-if="activeTab.object.formMode && activeTab.object.codeMode" class="bl-icon-button" v-bl-tooltip="'Code view'" :class="{active: activeTab.codeModeEnabled}" @click="setCodeMode(true)">data_object</button>
						</div>
						<div v-if="activeTab && activeTab.subModels && activeTab.subModels.length > 1 && activeTab.codeModeEnabled" class="subModels">
							<button class="bl-icon-button" v-for="subModel in activeTab.subModels" :key="subModel.id" v-bl-tooltip="subModel.label" @click="subModelClick(subModel)" :class="{active: subModel.active}">
								{{ subModel.icon }}
							</button>
						</div>
						<div style="flex: 1;"></div>
						<div>
							<button class="bl-icon-button" v-bl-tooltip="'Database manager'" @click="openDatabaseManager()">database</button>
							<button class="bl-icon-button" v-if="showFullscreen" v-bl-tooltip="'Toggle fullscreen'" @click="toggleFullscreen(); saveState();">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</button>
							<button class="bl-icon-button" v-bl-tooltip="'Settings'" @click="openSettings()">settings</button>
							<button class="bl-icon-button" v-bl-tooltip="'Logger'" @click="showLogs = !showLogs; saveState();" :class="{active: showLogs}">rss_feed</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="logContainer" v-if="showLogs">
			<button class="bl-icon-button clearButton" @click="$refs.logViewer.clear()">block</button>
			<BlLogViewer ref="logViewer" :channels="logChannels" style="margin: 0;" />
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Dialog, Variables, ViewServices, EventEmitter } from 'InterfaceBundle'
import InterfaceIntegratedEditorNavigation from './InterfaceIntegratedEditorNavigation'
import InterfaceIntegratedEditorSearch from './InterfaceIntegratedEditorSearch'
import { MonacoLoader } from 'InstallBundle'

export default {
	name: 'InterfaceIntegratedEditor',
	components: {
		InterfaceIntegratedEditorNavigation,
		InterfaceIntegratedEditorSearch
	},
	data() {
		return {
			navigationWidth: 200,
			tree: {nodes: [], namespaceId: null},
			tabs: [],
			activeTab: null,
			codeMode: true,
			showLogs: false,
			logChannels: ['system'],
			logChannelByTab: {},
			fullscreen: false,
			doc: {},
			showFullscreen: true
		}
	},
	created() {
		const manifestElement = document.querySelector('head > link[rel="manifest"]')
		this.initialManifest = [manifestElement, manifestElement.href]
		manifestElement.href = '/api/integrated-editor/manifest.json'
		this.restoreState()
		this.tabIdIterator = 1
		let req = {paths: {}, settings: {}, models: {}, doc: {}}
		req.paths['context("paths"):model.get("internals.namespace").findAll({"label":"ASC"})'] = {
			'loop("path"):local.paths': {
				id: null,
				label: null,
				parent: 'local.path.namespace.id'
			}
		}
		req.settings['context("settings"):script.run("internalInstallEditorGetSettings")'] = {
			data: 'local.settings'
		}
		req.models['context("models"):script.run("internalInstallEditorGetElements")'] = {
			'loop("model"):local.models': {
				extension: 'local.model.getExtension()',
				model: 'local.model.getModel()',
				labelProperty: 'local.model.getLabelProperty()',
				icon: 'local.model.getIcon()',
				color: 'local.model.getColor()',
				component: 'local.model.getComponent()',
				codeMode: 'local.model.getCodeMode()',
				formMode: 'local.model.getFormMode()'
			}
		}
		req.doc['context("doc"):script.run("internalInstallEditorGetDocumentation")'] = {
			data: 'local.doc'
		}

		Api.post('api/structure/', req).then(resp => {
			let i = 1
			this.models = resp.models.map(m => {
				m.key = 'k' + i
				i++
				return m
			})
			this.settings = resp.settings.data
			this.buildTree(resp.paths, this.tree)
			MonacoLoader.loadEditor(() => {
				let options = this.settings?.editor ? this.settings.editor : {}
				if(!options.fontSize) options.fontSize = '12px'
				options.model = null
				if(!options.theme) options.theme = 'vs-' + Variables.theme
				options.automaticLayout = true
				this.doc = resp.doc.data
				if(this.doc?.docs) this.registerAutoCompletions()
				this.editor = window.monaco.editor.create(this.$refs.codeEditor, options)
				this.keybindingRules()
				this.restoreTree()
			})
		})
		document.body.addEventListener('mousedown', this.mousedownEventListener)
		document.addEventListener('keydown', this.handleKeyboardShortcuts)
		this.tabEditorModel = {}
	},
	mounted() {
		this.showFullscreen = !window.matchMedia('(display-mode: standalone)').matches
		if(!this.showFullscreen) this.toggleFullscreen()
	},
	unmounted() {
		this.initialManifest[0].href = this.initialManifest[1]
		if(this.fullscreen) this.toggleFullscreen()
		document.body.removeEventListener('mousedown', this.mousedownEventListener)
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
		for(let tab of this.tabs) {
			if(tab.afterRequestSub) tab.afterRequestSub.unsubscribe()
		}
	},
	methods: {
		toggleFullscreen() {
			if(this.fullscreen && !this.showFullscreen) return
			this.fullscreen = !this.fullscreen
			document.querySelectorAll('.mainContainer > .level1, .mainContainer > .level2').forEach(e => e.style.display = this.fullscreen ? 'none' : null)
			const sideBarContainer = document.querySelector('.mainContainer > .blSidebarContainer')
			if(sideBarContainer) sideBarContainer.style.display = this.fullscreen ? 'none' : null
		},
		mousedownEventListener(event) {
			if(event.button == 1) {
				let isTab = false
				for(let element of event.composedPath()) {
					if(element.classList && element.classList.contains('tabs')) {
						isTab = true
						break
					}
				}
				if(isTab) {
					event.preventDefault()
					return false
				}
			}
		},
		updateEditor() {
			if(this.activeTab.codeModeEnabled) {
				const editorValue = this.activeTab.component.getEditorValues()
				for(let item of editorValue) {
					if(item.autocompletion) this.registerSignatureHelpers(item)
				}
				if(!this.tabEditorModel[this.activeTab.tabId]) {
					this.tabEditorModel[this.activeTab.tabId] = []
					for(let item of editorValue) this.tabEditorModel[this.activeTab.tabId].push(window.monaco.editor.createModel(item.value, item.language))
					if(editorValue.length) {
						this.activeTab.subModels = []
						for(let i in editorValue) this.activeTab.subModels.push({id: i, label: editorValue[i].label, icon: editorValue[i].icon, click: editorValue[i].click})
					}
				}
				else {
					for(let i in editorValue) {
						this.tabEditorModel[this.activeTab.tabId][i].setValue(editorValue[i].value ? editorValue[i].value : '')
						window.monaco.editor.setModelLanguage(this.tabEditorModel[this.activeTab.tabId][i], editorValue[i].language)
					}
				}
				let activeIndex = 0
				for(let item of this.activeTab.subModels) {
					if(item.active) activeIndex = item.id
				}
				this.setEditorModel(activeIndex)
			}
		},
		setEditorModel(index) {
			if(!this.activeTab.subModels[index].active) this.leaveModel(false)
			this.activeTab.subModels[index].active = true
			this.editor.setModel(this.tabEditorModel[this.activeTab.tabId][index])
			this.$nextTick(() => {
				this.$refs.codeEditor.style.width = '500px'//Force resize editor
				if(this.activeTab.subModels[index].viewState) this.editor.restoreViewState(this.activeTab.subModels[index].viewState)
				this.editor.focus()
			})
		},
		subModelClick(subModel) {
			if(subModel.active) return
			if(subModel.click) subModel.click()
			else this.setEditorModel(subModel.id)
		},
		leaveModel(keepActive) {
			if(!this.activeTab?.subModels) return
			for(let item of this.activeTab.subModels) {
				if(item.active) {
					if(!keepActive) item.active = false
					item.viewState = this.editor.saveViewState()
				}
			}
		},
		updateForm(force = false) {
			if(!this.activeTab) return
			if((!this.activeTab.codeModeEnabled || force) && this.tabEditorModel[this.activeTab.tabId]) {
				const values = this.tabEditorModel[this.activeTab.tabId].map(v => v.getValue())
				if(typeof this.activeTab.component.setEditorValues === 'function') this.activeTab.component.setEditorValues(values)
			}
		},
		setCodeMode(value) {
			if(this.activeTab.codeModeEnabled === value) return
			if(!this.activeTab.object.codeMode && value) return
			this.codeMode = value
			if(!value) this.leaveModel(true)
			this.activeTab.codeModeEnabled = value
			this.updateEditor()
			this.updateForm()
		},
		buildTree(data, parent) {
			for(let item of data) {
				if(item.parent === parent.namespaceId) {
					delete item.__route
					const subTree = {
						label: item.label,
						icon: 'folder',
						moveIcon: 'folder',
						namespaceId: item.id,
						parent: parent,
						nodes: []
					}
					parent.nodes.push(subTree)
					this.buildTree(data, subTree)
				}
			}
		},
		toggleItem(item, highlight = false) {
			let ret = null
			if(item.namespaceId) ret = this.togglePath(item)
			else {
				item.tabOpened = true
				if(highlight) {
					this.$nextTick(() => {
						const element = document.getElementById('installIntegratedEditorItem|' + item.model + '|' + item.id)
						element.scrollIntoView({block: 'center'})
					})
					item.highlight = true
					setTimeout(() => item.highlight = false, 500)
				}
				for(let tab of this.tabs) {
					if(tab.object === item) {
						this.openTab(tab)
						return
					}
				}
				this.tabIdIterator++
				const tab = {
					tabId: this.tabIdIterator,
					tabIcon: item.icon,
					tabColor: item.color,
					tabLabel: item.label,
					tabExtension: item.extension,
					tabLoading: true,
					object: item
				}
				this.tabs.push(tab)
				ret = this.openTab(tab)
			}
			this.saveState()
			return ret
		},
		togglePath(item) {
			let ret = new EventEmitter()
			item.opened = !item.opened
			item.icon = item.opened ? 'folder_open' : 'folder'
			//Load elements
			if(item.namespaceId != -1 && item.opened && !item.loading && !item.loaded) {
				item.loading = true
				let req = {}
				for(let meta of this.models) {
					req[meta.key] = {
						model: meta.model,
						fields: [{
							name: meta.labelProperty
						}, {
							name: 'id'
						}],
						limit: -1,
						sort: [{
							field: meta.labelProperty,
							order: 'ASC'
						}],
						filters: ['namespace.id', '=', item.namespaceId],
						metadata: false
					}
				}
				Api.post('api/', req).then(resp => {
					for(let meta of this.models) {
						for(let obj of resp[meta.key].data) {
							item.nodes.push({
								parent: item,
								icon: meta.icon,
								color: meta.color,
								label: obj[meta.labelProperty],
								id: obj.id,
								component: meta.component,
								model: meta.model,
								codeMode: meta.codeMode,
								formMode: meta.formMode,
								nsId: item.namespaceId,
								extension: meta.extension
							})
						}
					}
					item.loading = false
					item.loaded = true
					ret.emit()
				})
			}
			return ret
		},
		openTab(tab) {
			let ret = new EventEmitter()
			if(this.codeMode) this.leaveModel(true)
			if(this.activeTab) this.updateForm(true)
			if(!this.codeMode) this.codeMode = true
			for(let t of this.tabs) t.active = false
			tab.active = true
			let displayPath = [tab.object.label + (tab.object.extension ? '.' + tab.object.extension : '')]
			let parent = tab.object.parent
			do {
				if(parent?.label) displayPath.push(parent.label)
				parent = parent?.parent
			} while(parent)
			tab.displayPath = displayPath.reverse().join(' > ')
			const modelTabParts = tab.object.model.split('.')
			tab.displayPath += ' (' + modelTabParts[modelTabParts.length - 1] + ')'
			this.activeTab = tab
			this.updateEditor()
			this.$nextTick(() => {
				this.activeTab.component.$refs.form.formReady.subscribe(() => {
					this.getLogChannel().subscribe(logChannel => {
						if(logChannel) {
							this.logChannelByTab[this.activeTab.tabId] = logChannel
							this.setLogChannels()
						}
					})
					this.activeTab.afterRequestSub = this.activeTab.component.$refs.form.afterRequest.subscribe(response => {
						delete this.activeTab.errors
						if(response.valid) {
							//For new objects, subscribe to log
							setTimeout(() => {
								if(!this.logChannelByTab[this.activeTab.tabId]) {
									this.getLogChannel().subscribe(logChannel => {
										if(logChannel) {
											this.logChannelByTab[this.activeTab.tabId] = logChannel
											this.setLogChannels()
										}
									})
								}
							}, 100)
							this.activeTab.object.label = this.activeTab.tabLabel
							if(!this.activeTab.object.id) {
								const obj = {
									icon: this.activeTab.tabIcon,
									parent: this.activeTab.reloadPath,
									color: this.activeTab.tabColor,
									label: this.activeTab.tabLabel,
									extension: this.activeTab.tabExtension,
									id: response.response.__id,
									component: this.activeTab.object.component,
									model: this.activeTab.object.model,
									codeMode: this.activeTab.object.codeMode,
									formMode: this.activeTab.object.formMode,
									nsId: this.activeTab.reloadPath.namespaceId
								}
								this.activeTab.object = obj
								this.activeTab.reloadPath.nodes.push(obj)
								delete this.activeTab.reloadPath
							}
						}
						else {
							if(this.activeTab.object.formMode) this.setCodeMode(false)
							else this.activeTab.errors = response.response
						}
					})
					this.activeTab.component.$refs.form.disableSaveShortcut = true
					this.setCodeMode(this.codeMode)
					this.$nextTick(() => ret.emit(true))
				})
			})
			return ret
		},
		getLogChannel() {
			let editorChannel = typeof this.activeTab.component.getEditorLogChannel !== 'undefined' ? this.activeTab.component.getEditorLogChannel() : null
			let autoCall = null
			if(!(editorChannel instanceof EventEmitter)) {
				autoCall = editorChannel
				editorChannel = new EventEmitter()
			}
			if(autoCall) this.$nextTick(() => editorChannel.emit(autoCall))
			return editorChannel
		},
		closeTab(tab, event = null) {
			if(event && event.button != 1) return
			if(tab.unsavedChanges) {
				Dialog.confirm({
					title: 'Save changes ?',
					accept: 'Save',
					cancel: 'Discard'
				})
				.then(() => this.doCloseTab(tab))
				.catch(() => this.doCloseTab(tab))
			}
			else this.doCloseTab(tab)
			if(event) {
				event.preventDefault()
				event.stopPropagation()
				return false
			}
		},
		doCloseTab(tab) {
			tab.object.tabOpened = false
			if(this.logChannelByTab[tab.tabId]) {
				delete this.logChannelByTab[tab.tabId]
				this.setLogChannels()
			}
			delete this.tabEditorModel[tab.tabId]
			if(tab.afterRequestSub) tab.afterRequestSub.unsubscribe()
			if(tab.active) {
				this.activeTab = null
				let indexToFocus = null
				for(let index in this.tabs) {
					if(this.tabs[index].tabId == tab.tabId) {
						if(parseInt(index) !== 0) indexToFocus = parseInt(index) - 1
						else if(this.tabs.length > 1) indexToFocus = 1
					}
				}
				if(indexToFocus !== null) this.openTab(this.tabs[indexToFocus])
			}
			this.tabs = this.tabs.filter(t => t.tabId != tab.tabId)
			this.saveState()
		},
		wheelTabHandler(event) {
			event.preventDefault()
			this.$refs.tabContainer.scrollLeft += event.deltaY
		},
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey && !Dialog.getCurrentDialog() && this.activeTab) {
				event.preventDefault()
				event.stopPropagation()
				this.updateForm(true)
				this.$nextTick(() => {
					if(!this.activeTab.component.$refs.form.currentRequest) {
						this.activeTab.component.$refs.form.submit()
						this.$nextTick(() => {
							if(!this.activeTab.component.$refs.form.form.isValid()) {
								this.setCodeMode(false)
								this.$nextTick(() => this.activeTab.component.$refs.form.submit())
							}
						})
					}
				})
				return false
			}
		},
		keybindingRules() {
			if(!this.settings.shortcuts) return
			let rules = []
			for(let shortcut of this.settings.shortcuts) {
				let keybinding = shortcut.keybinding.map(i => {
					const parts = i.split('.')
					let value = window.monaco
					for(let part of parts) value = value[part]
					return value
				}).reduce((i, s) => i | s, 0)
				rules.push({keybinding: keybinding, command: shortcut.command})
			}
			window.monaco.editor.addKeybindingRules(rules)
		},
		addElement(object, event) {
			if(object.namespaceId && !object.opened) this.togglePath(object)
			event.stopPropagation()
			event.preventDefault()
			Dialog.custom({
				closeButton: false,
				component: 'InterfaceIntegratedEditorCreateDialog',
				componentProps: {object: object, models: this.models, tree: this.tree}
			}).then(type => {
				if(type == 'namespace') {
					Dialog.prompt({
						title: 'New folder',
						accept: 'Create',
						cancel: 'Cancel',
						required: true,
						promptLabel: 'Path'
					}).then(namespaceLabel => {
						Api.post('form/internals.namespace', {submit: {label: namespaceLabel, namespace: object.namespaceId}}).then(resp => {
							object.nodes.unshift({
								label: namespaceLabel,
								icon: 'folder',
								namespaceId: resp.__id,
								parent: object,
								nodes: []
							})
						})
					})
				}
				else {
					const item = this.models.filter(m => m.model == type)[0]
					this.tabIdIterator++
					const tab = {
						tabId: this.tabIdIterator,
						tabIcon: item.icon,
						tabColor: item.color,
						tabLabel: item.label,
						tabExtension: item.extension,
						tabLoading: true,
						object: JSON.parse(JSON.stringify(item)),
						reloadPath: object
					}
					tab.object.nsId = object.namespaceId
					this.codeMode = !item.formMode
					this.tabs.push(tab)
					this.openTab(tab)
				}
			})
		},
		updateTabName(name) {
			this.activeTab.tabLabel = name
		},
		openSettings() {
			let settingsTab = null
			for(let tab of this.tabs) {
				if(tab.tabId === '_settings_') settingsTab = tab
			}
			if(!settingsTab) {
				const req = {data: {
					model: 'internals.parameter',
					fields: [{
						name: 'id'
					}],
					limit: 1,
					filters: ['key', '=', 'EDITOR_SETTINGS_' + ViewServices.interfaceData.userPreferences.id],
					metadata: false
				}}

				Api.post('api/', req).then(resp => {
					settingsTab = {
						tabId: '_settings_',
						tabColor: '#666',
						tabIcon: 'settings',
						tabLabel: 'Settings',
						parent: {},
						object: {
							codeMode: true,
							color: '#666',
							icon: 'settings',
							model: 'internals.parameter',
							label: 'Settings',
							component: 'InternalParameterForm',
							id: resp.data.data[0].id,
							nsId: -1
						}
					}
					this.tabs.push(settingsTab)
					this.openTab(settingsTab)
				})
			}
			else this.openTab(settingsTab)
		},
		setLogChannels() {
			let channels = ['system']
			for(let item of Object.values(this.logChannelByTab)) {
				if(!channels.includes(item)) channels.push(item)
			}
			this.logChannels = channels
		},
		saveState() {
			const state = {
				showLogs: this.showLogs,
				fullscreen: this.fullscreen,
				navigationWidth: this.navigationWidth,
				_namespaces: this.saveStateParseNamespaces(this.tree),
				_tabs: this.tabs.map(t => {
					return {component: t.object.component, id: t.object.id}
				})
			}
			localStorage.setItem('install.editorState', JSON.stringify(state))
		},
		saveStateParseNamespaces(tree) {
			let ret = []
			for(let node of tree.nodes) {
				if(!node.opened) continue
				ret.push(node.namespaceId)
				if(node.nodes) ret = ret.concat(this.saveStateParseNamespaces(node))
			}
			return ret
		},
		restoreState() {
			let state = localStorage.getItem('install.editorState')
			if(!state) return
			state = JSON.parse(state)
			for(let key in state) {
				if(key == 'fullscreen' && this.showFullscreen) {
					if(state[key]) this.toggleFullscreen()
				}
				else if(typeof this[key] !== 'undefined') this[key] = state[key]
			}
		},
		async restoreTree() {
			let state = localStorage.getItem('install.editorState')
			if(!state) return
			state = JSON.parse(state)
			
			//Path
			let remainingToOpen = state._namespaces
			let pathOpened = true
			while(pathOpened) {
				pathOpened = false
				let flatTree = this.flattenTree()
				for(let item of flatTree) {
					if(remainingToOpen.includes(item.namespaceId)) {
						pathOpened = true
						remainingToOpen = remainingToOpen.filter(i => i != item.namespaceId)
						await new Promise(resolve => this.togglePath(item).once(() => resolve()))
					}
				}
			}

			//Tabs
			for(let item of this.flattenTree()) {
				for(let tab of state._tabs) {
					if(item.component == tab.component && item.id == tab.id) {
						await new Promise(resolve => this.toggleItem(item).once(() => resolve()))
						break
					}
				}
			}
		},
		flattenTree(tree = null) {
			let ret = []
			if(!tree) tree = this.tree
			for(let node of tree.nodes) {
				ret.push(node)
				if(node.nodes) ret = ret.concat(this.flattenTree(node))
			}
			return ret
		},
		async openSearchResult(result) {
			let tree = this.tree.nodes
			for(let namespaceId of result.namespace.ids) {
				let item = tree.filter(i => i.namespaceId == namespaceId)
				if(!item.length) return
				item = item[0]
				if(!item.loaded) await new Promise(resolve => this.togglePath(item).once(() => resolve()))
				item.opened = true
				item.icon = 'folder_open'
				tree = item.nodes
			}
			if(!tree) return
			result = tree.filter(i => i.model == result.model && i.id == result.id)
			if(!result.length) return
			this.toggleItem(result[0], true)

		},
		openDatabaseManager() {
			window.open('/internals/databasemanager/')
		},
		registerAutoCompletions() {
			for(let article of this.doc.docs) {
				window.monaco.languages.registerCompletionItemProvider(article.language, {
					triggerCharacters: article.triggers,
					provideCompletionItems: (model, position) => {
						if(article.path) {
							let path = article.path
							path.push('')
							let lastChars = model.getValueInRange({startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column})
							let words = lastChars.replaceAll('\t', '').split('.')
							for(let i = 0; i < words.length; i++) {
								if(path[i] !== words[i]) {
									path = []
									return {suggestions: []}
								}
							}
						}
						return {
							suggestions: [{
								label: article.label,
								kind: window.monaco.languages.CompletionItemKind[article.kind],
								documentation: {value: article.documentation},
								insertTextRules: window.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
								insertText: article.insertText
							}]
						}
					}
				})
			}
		},
		registerSignatureHelpers(item) {
			window.monaco.languages.registerSignatureHelpProvider(item.language, {
				signatureHelpTriggerCharacters: item.triggers,
				provideSignatureHelp: (model, position) => {
					let lastChars = model.getValueInRange({startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column})
					let autoCompletionData = item.autocompletion(model, position)
					let signatures = []
					for(let signature of this.doc.signatures) {
						let prefix = signature.path ? signature.path.join('.') + '.' : '<'
						if(lastChars === (prefix + signature.name + '(') && item.language === signature.language) {
							let line = {label: signature.label, documentation: {value: signature.documentation}, parameters: []}
							for(let parameter of signature.args) {
								line.parameters.push({label: parameter.name, documentation: {value: parameter.description}})
							}
							signatures.push(line)
						}
					}
					return {
						value: autoCompletionData.match ? {
							signatures: signatures,
							activeSignature: 0,
							activeParameter: autoCompletionData.nbParameters
						} : {signatures: []},
						dispose: () => {}
					}
				}
			})
		}
	},
	provide() {
		return {
			toggleItem: this.toggleItem,
			addElement: this.addElement,
			integratedEditorUpdateName: this.updateTabName,
			integratedEditorOpenSearchResult: this.openSearchResult
		}
	}
}
</script>

<style scoped lang="scss">
	.navigationContainer {
		padding: 2px;
		display: flex;
		flex-direction: column;

		> div.navigation {
			overflow: auto;
			flex: 1;

			> ul {
				padding: 0;
			}
		}
	}

	.sideBar {
		border-left: 1px solid var(--bl-border);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 4px;

		.subModels {
			border-top: 1px solid var(--bl-border);
		}

		button {
			margin: 6px 0;
			display: block;
			min-width: 50px;
			transition: all .2s;
			border: 1px solid var(--bl-background);
		}

		button::after {
			top: 0 !important;
		}

		button.active {
			color: var(--bl-primary);
			background-color: var(--bl-surface);
			border: 1px solid var(--bl-primary);
		}
	}

	div.bl-resize-handler {
		height: auto;
		z-index: 5;
	}

	div.bl-resize-handler:not(:hover) {
		min-width: 2px;
	}

	.contentContainer {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	.filePath {
		font-size: 11px;
		color: var(--bl-legend);
		font-weight: 500;
		padding: 4px 5px;
		min-height: 13px;
	}

	.tabErrors {
		background-color: var(--bl-surface);
		color: var(--bl-error);
		font-size: 12px;
		padding: 5px 10px;
		border-right: 1px solid var(--bl-border);

		icon {
			color: var(--bl-on-surface);
			font-size: 20px;
			margin-right: -4px;
			float: right;
			cursor: pointer;
			user-select: none;
		}
	}

	.tabs {
		list-style-type: none;
		display: flex;
		margin: 0;
		padding: 0;
		min-height: 35px;
		border-bottom: 1px solid var(--bl-border);
		overflow-x: auto;

		li {
			display: flex;
			align-items: center;
			color: var(--bl-legend);
			cursor: pointer;
			padding: 0 0 0 10px;
			font-size: 12px;
			border-top-right-radius: var(--bl-border-radius);
			border-top-left-radius: var(--bl-border-radius);
			user-select: none;
			position: relative;

			button {
				font-size: 13px;
			}

			icon {
				font-size: 16px;
				margin-right: 5px;
			}
		}

		li:hover {
			color: var(--bl-on-surface);
		}

		li.active {
			color: var(--bl-on-surface);
			background-color: var(--bl-surface);
		}

		li.soft {
			font-weight: 400;
			font-style: italic;
		}
	}

	.logContainer {
		border-top: 1px solid var(--bl-border);
		background-color: var(--bl-surface);
		max-height: 120px;
		min-height: 120px;
		position: relative;
		height: 120px;

		.clearButton {
			color: var(--bl-legend);
			font-size: 17px;
			position: absolute;
			right: 0;
		}

		:deep > div {
			max-height: inherit;
		}

		:deep .bl-card {
			margin: 0;
			padding: 0;
			background-color: transparent;
			border: 0;
			font-size: 12px;
			max-height: inherit;

			> button {
				display: none;
			}

			div.bl-light-scroll > div {
				padding-top: 2px;
				padding-bottom: 1px;
				border-radius: 0;
			}

			div.bl-light-scroll > div span {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
</style>
